import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const ServiceDetails = () => {
  return (
    <div className="p-5 md:my-16 lg:my-28 max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-12">
      <div className="col-span-12 lg:col-span-4 p-5 order-2 lg:order-1 md:p-10">
        <h3 className="text-[22px] md:text-3xl font-semibold">Services</h3>
      </div>
      <div className="col-span-12 lg:col-span-8 md:px-5 lg:order-2">
        <Swiper
          slidesPerView={1}
          modules={[Navigation, Autoplay]}
          autoplay={{ delay: 5000 }}
          speed={2000}
          spaceBetween={10}
          navigation
        >
          {Array(6)
            .fill(0)
            .map((item, idx) => (
              <SwiperSlide key={idx} className="bg-red-500">
                <img
                  src={`/images/service-details-${idx + 1}.jpg`}
                  alt=""
                  className="w-full h-52 md:h-[500px] object-cover object-center"
                />
              </SwiperSlide>
            ))}
        </Swiper>

        <div className="grid gap-5">
          <div className="col-span-12">
            <h2 className="text-3xl font-bold my-5">Construction</h2>
            <p>
              Nailed Construction" is a renowned and trusted construction
              company that specializes in delivering high-quality projects. With
              a dedicated team of skilled professionals and a commitment to
              excellence, they have successfully completed a wide range of
              residential and commercial ventures. Their attention to detail,
              innovative approach, and timely project completion have earned
              them a stellar reputation in the construction industry. Whether
              it's building dream homes or executing large-scale infrastructure
              projects, "Nailed Construction" remains a reliable choice for
              clients seeking top-notch construction solutions
            </p>
          </div>
          <div className="col-span-12 lg:col-span-6">
            <h3 className="font-bold text-xl mb-2 text-gray-900">
              Project Analysis
            </h3>
            <p>
              Nailed Construction conducts thorough Project Analysis before
              starting any project. They evaluate objectives, scope, resources,
              risks, and benefits to ensure successful and well-planned
              execution.
            </p>
          </div>
          <div className="col-span-12 lg:col-span-6">
            <h3 className="font-bold text-xl mb-2 text-gray-900">
              Project Costing
            </h3>
            <p>
              At Nailed Construction we carefully estimate project costs,
              including materials, labor, overheads, and subcontractors. A
              contingency budget is included for unexpected events, ensuring
              successful project delivery within budgetary limit
            </p>
          </div>
          <div className="col-span-12 lg:col-span-6">
            <h3 className="font-bold text-xl mb-2 text-gray-900">
              Project Planning
            </h3>
            <p>
              Project Planning at Nailed Construction involves defining
              objectives, scoping, resource allocation, scheduling, risk
              assessment, communication planning, and quality assurance. This
              comprehensive approach ensures efficiency and successful project
              execution.
            </p>
          </div>
          <div className="col-span-12 lg:col-span-6">
            <h3 className="font-bold text-xl mb-2 text-gray-900">
              Project Strategy
            </h3>
            <p>
              At Nailed Construction we employ a strategic approach to plan,
              execute, and deliver successful projects, focusing on efficiency,
              quality, and client satisfaction.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetails;
