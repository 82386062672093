import { useAppStore } from "../store/appStore";
import { Autoplay, EffectCoverflow, Pagination, Virtual } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/virtual';

export const TestimonialsSection = () => {
  const appData = useAppStore((store) => store.appData);

  return (
    <section style={{ backgroundColor: '#f3f4f6' }}>
      {/* <h2 className="section-title">Testimonials</h2>
        <p className="section-subtitle mb-8">What our clients say about us</p> */}
          
      <section
        className="py-20 relative"
        style={{ 
          backgroundImage: `url(${appData?.about_us_video})`, 
          backgroundSize: "cover", 
          backgroundPosition: "center" 
        }}
      >
        <div className="absolute inset-0 bg-black opacity-30"></div>

        <div className="container mx-auto">
          <div className="mt-10 max-w-3xl mx-auto">
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={"auto"}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 2.5,
              slideShadows: true,
            }}
            loop={true}
            pagination={{ clickable: true }}
            autoplay={{
              delay: 6000,
              disableOnInteraction: false,
            }}
            modules={[EffectCoverflow, Pagination, Autoplay]}
          >
              {appData?.testimonials?.map((testimonial: any, index) => (
                <SwiperSlide key={testimonial.name} virtualIndex={index} className="bg-white border border-gray-200 p-6 rounded-lg flex-shrink-0 w-150 black">
                  <div className="flex items-center mb-4">
                    <img src={testimonial.profile_image} alt={testimonial.name} className="w-20 h-20 rounded-full mr-4" />
                    <div>
                      <p className="text-lg">{testimonial.description}</p>
                      <p className="font-semibold mt-1">{testimonial.name}</p>
                      <p className="text-gray-500">{testimonial.designation}</p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </section>
  );
};
