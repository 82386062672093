import React, { useEffect, useRef } from "react";
import { useAppStore } from "../store/appStore";
import { Link } from "react-router-dom";
import { FaFacebook, FaLinkedin, FaTwitter } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";
import { Swiper, SwiperSlide } from "swiper/react";
import video from "../assets/videos/lemarinivid.mp4";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

// import required modules
import { Autoplay, EffectCoverflow, Pagination } from "swiper/modules";
import { slugify } from "../utils/slugify";
import { StatisticCounter } from "../components/StatisticCounter";
import { Partner } from "../components/Partner";
import { TestimonialsSection } from "../components/Testimonial";

const HomePage = () => {
  const appData = useAppStore((store) => store.appData);
  const testimonialContainerRef = useRef<HTMLDivElement>(null);
  console.log({ appData });

  useEffect(() => {
    const testimonialContainer = testimonialContainerRef.current;
    if (testimonialContainer) {
      // Scroll every 5 seconds
      const scrollInterval = setInterval(() => {
        testimonialContainer.scrollTo({
          left: testimonialContainer.scrollLeft + testimonialContainer.clientWidth, // Scroll by one testimonial width
          behavior: "smooth",
        });
      }, 5000);

      // Cleanup interval on component unmount
      return () => clearInterval(scrollInterval);
    }
  }, []);

  return (
    <div className="pt-16 md:pt-0">
      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 items-center my-10 px-5 md:px-10 lg:px-28">
        <div className="flex-1 flex flex-col justify-center items-start gap-3 md:col-span-5">
          <h1 className="text-xl md:text-xl lg:text-4xl lg:leading-10 font-semibold text-gray-900 w-11/12">
            {appData?.header_section_title}
          </h1>
          <p>{appData?.header_section_description}</p>
          <button className="btn bg-orange-600">
            <Link to="/about">Learn more</Link>
          </button>
        </div>
        <div className="relative rounded-2xl flex-1 overflow-hidden md:col-span-7">
          <div className="hidden md:block h-full border-green-500">
            {appData?.header_section_video ? (
              <video
                src={appData?.header_section_video}
                autoPlay={true}
                muted
                loop={true}
                className="object-cover w-full"
              ></video>
            ) : (
              <video
                src={video}
                autoPlay
                muted
                loop
                className="object-cover w-full"
              ></video>
            )}
          </div>
          <div className="md:hidden">
            <img src={appData?.header_section_image} alt="" />
          </div>
          <div className="absolute bg-black/50 inset-0"></div>
        </div>
      </div>

      {/* Download Our Mobile Apps */}
      <div className="mobile-app h-48 md:h-56 lg:h-72  flex flex-col gap-3 items-center justify-center">
        {/* <div className="absolute inset-0 bg-orange-600 opacity-50 -z-0"></div> */}
        <h4 className="font-bold text-xl lg:text-2xl text-white">
          Download Our Mobile Apps
        </h4>
        <div className="flex gap-5">
          <a href={appData?.android_app_link}>
            <img src="/images/play-store.png" alt="" className="w-40" />
          </a>
          <a href={appData?.ios_app_link}>
            <img src="/images/app-store.png" alt="" className="w-40" />
          </a>
        </div>
      </div>

      {/* About Us Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-10 lg:gap-5 p-5 md:p-10 lg:p-28">
        <div className="lg:w-[80%]">
          <h4 className="text-wine font-semibold">ABOUT US</h4>
          <h3 className="text-2xl md:text-4xl font-semibold md:mt-5">
            {appData?.about_us_title
              ? appData?.about_us_title
              : "We are one of the Best Construction Companies worldwide"}
          </h3>
          <p className="leading-8 text-lg my-4 md:my-8">
            {appData?.about_us_description
              ? appData?.about_us_description
              : "We help make your dream of having a home come true. You dream it, we build it for you.Enjoy custom cabinets designed and carefully built to suit Your needs. From inspiration to cabinet installation. At Nailed we combine our experience with expert contractors to put up Your Dream Home. Let us build the home of your dreams in your most desired location."}
          </p>
          <button className="bg-orange-600 rounded-full text-white py-5 px-14 text-xs">
            <Link to="/contact-us">
              CONTACT US
            </Link>
          </button>
        </div>
        <img
          src={
            appData?.about_us_video
              ? appData?.about_us_video
              : "/images/about.jpg"
          }
          alt=""
          className="h-full object-cover rounded-2xl"
        />
      </div>

      {/* Our Services */}
      <section className="p-5 md:p-10 lg:px-28">
        <div className="container px-5 mx-auto">
          <div className="flex flex-wrap w-full mb-20 flex-col items-center text-center">
            <h2 className="section-title">Our Services</h2>
            <p className="section-subtitle">
              We provide the following Services and More
            </p>
          </div>
          <div className="flex flex-wrap -m-4">
            {appData?.services?.map((service, index) => (
              <div
                key={index}
                className="xl:w-1/3 md:w-1/2 p-4 hover:bg-orange-600 hover:text-white duration-500 group cursor-pointer"
              >
                <div className="border border-gray-200 p-6 rounded-lg">
                  <div className="w-10 h-10 inline-flex items-center justify-center rounded-full overflow-hidden bg-blue-100 text-blue-500 mb-4">
                    <img
                      src={service.icon}
                      alt=""
                      className="w-10 h-10 object-cover"
                    />
                  </div>
                  <h2 className="text-lg text-gray-900 font-semibold mb-2 group-hover:text-white">
                    {service.title}
                  </h2>
                  <p className="leading-relaxed text-base line-clamp-4">
                    {service.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Latest Projects */}
      <section className="text-center py-20">
        <h2 className="section-title">Latest Projects</h2>
        <p className="section-subtitle">Nailed Constructions Limited</p>

        <div className="columns-3xs max-w-5xl mx-auto gap-5 p-5">
          {appData?.projects.map((project, idx) => (
            <div key={idx} className="relative group cursor-pointer">
              <img
                key={idx}
                src={project.thumbnail_image}
                alt=""
                className="rounded-md mb-5"
              />
              <div className="text-white absolute p-5 backdrop-blur-lg bg-orange-600 opacity-0 left-5 bottom-5 transform translate-y-10 group-hover:translate-y-0 duration-300 group-hover:opacity-70">
                <h3 className="font-semibold tracking-widest">
                  {project.title}
                </h3>
                <Link
                  to={`/projects/${slugify(project.title)}`}
                  className="text-sm"
                >
                  See More...
                </Link>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Fun Fact Section */}
      <section className="py-20 bg-orange-200">
        <h2 className="section-title">Accomplishments</h2>
        <p className="section-subtitle">We work for you.</p>

        <div className="flex flex-wrap justify-center mt-10 space-x-5">
        {appData?.about_us_fun_facts?.map((funfact: any, index) => (
          <div className="text-center" key={index}>
            <img src={funfact.icon} alt="Fun Fact Icon" className="w-8 h-8 mx-auto mb-3 rounded-lg" />
            <StatisticCounter count={funfact.count} /> {/* Example count */}
            <p className="text-gray-500">{funfact.title}</p>
        </div>
        ))}
        </div>
      </section>

      <section className="py-20 bg-gray-100">
      <h2 className="section-title">Our Partners</h2>
      <p className="section-subtitle">Trusted Collaborations</p>
        
      <SwiperSlide>
        <div className="flex justify-center mt-10 space-x-10">
          {appData?.about_us_partners.map((partner: any, index) => (
            <Partner key={index} partner={partner} />
          ))}
        </div>
      </SwiperSlide>
      </section>
      
      <TestimonialsSection />

      {/* Testimonial Section */}
      {/* <section className="py-20"> */}
        {/* <h2 className="section-title">Testimonials</h2>
        <p className="section-subtitle">What our clients say about us</p> */}

        {/* <div
          ref={testimonialContainerRef}
          className="max-w-7xl mx-auto mt-10 flex overflow-x-auto space-x-5 bg-gray-100 p-5 rounded-lg"
          style={{ scrollBehavior: "smooth", WebkitOverflowScrolling: "touch" }} // Smooth scrolling behavior
        > */}
          {/* Render testimonials */}
          {/* {appData?.testimonials?.map((testimonial: any, index) => (
            <div key={index} className="border border-gray-200 p-6 rounded-lg flex-shrink-0 w-96 black">
              <div className="flex items-center mb-4">
                <img src={testimonial.profile_image} alt={testimonial.name} className="w-20 h-20 rounded-full mr-4" />
                <div>
                  <p className="text-lg">{testimonial.description}</p>
                  <p className="font-semibold mt-1">{testimonial.name}</p>
                  <p className="text-gray-500">{testimonial.designation}</p>
                </div>
              </div>
            </div>
          ))} */}
        {/* </div> */}
      {/* </section> */}

      <section className="py-20">
        <h2 className="section-title">Our Team</h2>
        <p className="section-subtitle">Nailed Constructions Limited</p>

        <div className="mt-10 max-w-full mx-auto">
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={"auto"}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 2.5,
              slideShadows: true,
            }}
            loop={true}
            pagination={{ clickable: true }}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            modules={[EffectCoverflow, Pagination, Autoplay]}
          >
            {appData?.about_us_team.map((member, index) => (
              <SwiperSlide key={index} style={{ width: '300px' }}>
                <div className="relative bg-teal-500 h-96 w-80 rounded-md overflow-hidden cursor-pointer group">
                  <img
                    src={member?.profile_image}
                    alt={member?.name}
                    className="h-96 w-80 object-cover"
                  />
                  <div className="absolute flex flex-col justify-center items-center h-32 w-full backdrop-blur-md left-0 bg-white/5 transform -bottom-40 group-hover:bottom-0 duration-500">
                    <h3 className="text-white font-semibold tracking-[0.3em] text-xl uppercase">
                      {member?.name}
                    </h3>
                    <p className="text-white text-xs italic tracking-[0.25em]">
                      {member?.designation}
                    </p>

                    <div className="mt-3 text-white flex items-center justify-center gap-3 w-full">
                      {member?.facebook_link && (
                        <a
                          href={member?.facebook_link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FaFacebook size={20} />
                        </a>
                      )}
                      {member?.instagram_link && (
                        <a
                          href={member?.instagram_link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <GrInstagram fontSize={20} />
                        </a>
                      )}
                      {member?.linkedin_link && (
                        <a
                          href={member?.linkedin_link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FaLinkedin fontSize={20} />
                        </a>
                      )}
                      {member?.twitter_link && (
                        <a
                          href={member?.twitter_link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FaTwitter fontSize={20} />
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>

    </div>
  );
};

export default HomePage;
